// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.form-container {
  width: 800px;
  margin: 0 auto;

  h2 {
    color: var(--color-coral);
    font-size: 26px;
    font-weight: bold;
    line-height: 56px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-description {
    
    * {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.4px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  form {
    box-shadow: 0 1px 40px 0 rgba(40, 63, 116, 0.1);
    padding: 30px;

    .form-control {
      border-radius: 5px;
      padding: 10px 12px;
    }

    button {
      background-color: var(--color-coral);
      border: 0;
      color: var(--color-white);
      padding: 12px 30px;
    }
  }
}

@include lg {

  .form-container {
    width: 100%;
  }
}
