// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.event {
  display: flex;
  justify-content: center;

  .event-content {
    width: 1000px;

    h2 {
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      font-family: var(--font-din-condensed);
      margin-bottom: 30px;
    }

    .event-card {
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      color: var(--color-black);
      display: flex;
      padding: 30px;
      margin-bottom: 30px;
      transition: all 0.3s;
      position: relative;
      text-decoration: none;
      z-index: 1;

      .event-image {
        flex-basis: 35%;
        margin-right: 30px;

        img {
          aspect-ratio: 16/9;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .event-card-content {
        display: flex;
        flex-basis: 65%;

        .event-date {
          align-items: center;
          background: var(--color-aquamarine);
          color: var(--color-white);
          display: flex;
          flex-direction: column;
          font-weight: 700;
          font-family: var(--font-din-condensed);
          gap: 5px;
          justify-content: center;
          min-width: 130px;
          padding: 10px;
          text-align: center;
          text-transform: uppercase;
          transition: all 0.3s;

          p {
            margin: 0;
          }
        }

        .event-details {
          margin-left: 20px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .address {
            margin-bottom: 15px;

            &::before {
              content: '\f3c5';
              color: var(--color-aquamarine);
              font-weight: 600;
              font-family: var(--font-fontawesome);
              margin-right: 10px;
            }
          }

          .event-title {
            font-size: 26px;
            line-height: 26px;
            margin-bottom: 19px;
          }

          .event-description {
            font-size: 16px;
            line-height: 22px;

            p {
              margin-bottom: 0 !important;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include lg {

  .event {

    .event-content {

      h2 {
        font-size: 24px;
        line-height: 24px;
      }

      .event-card {
        flex-direction: column;
        gap: 30px;
        padding: 0;

        .event-image {
          margin-right: 0;
          width: 100%;
        }

        .event-card-content {
          flex-direction: column;
          gap: 10px;
          padding: 0 15px;

          .event-date {
            flex-direction: row;
          }

          .event-details {
            margin-left: 0;

            .address {
              margin-bottom: 5px;
            }

            .event-title {
              font-size: 22px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
