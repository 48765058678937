// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.footer {
  background-color: var(--color-coral);
  padding: 15px 0;

  .footer-inner {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }

  p {
    color: var(--color-white);
    margin: 0;
    text-align: center;
  }

  a {
    color: var(--color-white);

    &:hover {
      color: var(--color-aquamarine);
    }
  }

  .footer-title {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--color-white);
      font-size: 30px;
      line-height: 30px;
    }
  }
}

@include lg {

  .footer {
    
    .forewarnFooter {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
