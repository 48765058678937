// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.event-details-page {

  .event-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    .event-image {
      margin-bottom: 30px;
      margin-top: 20px;

      img {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
      }
    }

    .institution {
      margin-bottom: 25px;
    }

    .event-body {

      .title {
        margin-bottom: 10px;
      }

      .date,
      .address {
        text-align: center;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;

        span {
          color: var(--color-aquamarine);
        }
      }

      .institution {
        text-align: center;
      }

      .description {

        p {
          margin-bottom: 0;
        }

        ul {
          text-align: left;
        }
      }
    }
  }
}
