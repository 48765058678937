// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.anticipation {
  margin-top: 30px;

  h2 {
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    font-family: var(--font-din-condensed);
    margin-bottom: 30px;
  }

  .anticipation-card {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 40px;
    min-height: 400px;

    .anticipation-image {
      border: 1px solid var(--color-light-grey);
      display: flex;
      align-items: center;
      padding: 50px;
      height: 100%;

      img {
        aspect-ratio: 16/9;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .anticipation-card-content {
      background-color: var(--color-white-smoke);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 55px 60px 69px;

      .anticipation-title {
        color: var(--color-black);
        font-size: 26px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;

        &::after {
          content: '';
          border-bottom: 1px solid var(--color-dim-grey);
          display: block;
          margin: 15px auto 25px;
          width: 242px;
        }
      }

      .anticipation-date {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;

        .end-date {

          &::before {
            content: 'To';
            margin: 0 10px;
          }
        }
      }

      .institution {
        color: var(--color-black);
        font-size: 26px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
      }

      .address {
        margin-bottom: 0;
        text-align: center;
      }

      .anticipation-description {
        font-size: 16px;
        line-height: 22px;
        margin: 20px 0 0;

        p {
          margin-bottom: 0 !important;

          &:last-child {
            margin-bottom: 25px !important;
          }
        }
      }

      .anticipation-details-button {
        margin: 0 auto;
        margin-top: 25px;
        width: max-content;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include lg {

  .anticipation {

    h2 {
      font-size: 24px;
      line-height: 24px;
    }

    .anticipation-card {
      grid-template-columns: 1fr;

      .anticipation-image {
        padding: 0;
      }

      .anticipation-card-content {
        padding: 30px 20px;

        .anticipation-title {
          font-size: 22px;
          line-height: 22px;

          &::after {
            margin: 15px auto;
          }
        }

        .anticipation-date {
          margin-bottom: 15px;
        }
      }
    }
  }
}
