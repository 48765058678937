// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.details-page {

  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 56px;
    margin-bottom: 50px;
    text-align: center;
  }

  p {
    color: $blackColor;
    letter-spacing: 0.4px;
    text-align: justify;
  }

  img {
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
  }

  .video-div {

    iframe {
      width: 100%;
    }
  }

  .person-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    a {
      text-decoration: none;
      color: var(--color-black);

      .person-card {
        align-items: center;
        display: flex;
        gap: 30px;

        .person-image {
          flex-basis: 30%;
        }

        .person-body {
          flex-basis: 70%;
        }
      }
    }
  }
}

@include lg {

  .details-page {

    h1 {
      font-size: 32px;
      line-height: 32px;
    }
  }
}

@include md {

  .details-page {

    .video-div {

      iframe {
        height: 300px;
      }
    }

    iframe {
      width: 100%;
    }

    .person-wrapper {

      .person-card {
        align-items: start !important;
        flex-direction: column;
      }
    }
  }
}
