// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.sidebar-wrapper {
  padding: 15px;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;

  .map_type {

    .map-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      color: $bgColor;
      background-color: $greenColor;
      padding: 15px 30px 15px 15px;
      margin-bottom: 20px;

      .rotate {
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }

      &.collapsed {

        .rotate {
          transform: rotate(0deg);
        }
      }
    }

    .map-item {

      ul {
        list-style-type: none;
        margin-bottom: 10px;
        padding-left: 0;

        li {
          color: $grayColor;
          margin-bottom: 20px;

          label {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 0;

            input {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .cyclone_type{

    .cyclone-text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      background-color: $greenColor;
      color: $bgColor;
      padding: 15px 30px 15px 15px;
      margin-bottom: 20px;

      .rotate {
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }

      &.collapsed{

        .rotate{
          transform: rotate(0deg);
        }
      }
    }

    .cyclone-item{

      ul{
        list-style-type: none;
        margin-bottom: 10px;
        padding-left: 0;

        li{
          color: $grayColor;
          margin-bottom: 20px;

          label{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 0;

            input{
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .cyclone-detail{

    .cyclone-detail-text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      background-color: $greenColor;
      color: $bgColor;
      padding: 15px 30px 15px 15px;
      margin-bottom: 20px;

      .rotate {
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }

      &.collapsed{

        .rotate{
          transform: rotate(0deg);
        }
      }
    }

    .cyclone_table{

      tr{
        color: $grayColor;
        font-weight: 800;

        &:first-child{
          color: #79d9bc;
          background-color: #e7e7e7;

          td{
            padding: 15px 15px 15px 5px;
          }
        }

        &:nth-child(odd){
          background-color: #f8f7f7;
        }

        td{
          padding: 5px;
        }

        .d-hidden{
          display: none;
        }
      }
    }
  }

  .submit-div{
    display: flex;
    justify-content: end;
    margin-top: 20px;

    .submit-btn{
      background-color: #36e4af38;
      color: $grayColor;
      font-weight: bold;
      border: 2px solid $greenColor;
      text-transform: uppercase;
      padding: 10px 30px;
    }
  }
}
