// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.reach {
  padding-top: 30px;
  padding-bottom: 30px;

  .title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 0 !important;
  }

  .reach-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .reach-text {
      font-size: 18px;
    }
  }
}

@include lg {

  .reach {
    padding-top: 30px;
    padding-bottom: 30px;

    .title {
      font-size: 26px;
    }
  }
}

@include md {

  .reach {

    .reach-content {
      
      img {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
