// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.hazard {
  margin: 0 auto;
  max-width: 1000px;

  .hazard-header {
    font-size: 32px;
    font-weight: bold;
    font-family: var(--font-din-condensed);
    padding-bottom: 20px;
    text-align: center;
  }

  .hazard-content {

    .hazard-card {
      background-color: var(--color-coral);
      display: flex;
      margin-bottom: 30px;
      text-decoration: none;
      
      .hazard-card-content {
        align-self: center;
        color: var(--color-white);
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-basis: 50%;
        padding: 40px;

        .hazard-text {
          color: var(--color-white);
          font-size: 21px;
          font-weight: bold;
          font-family: var(--font-din-condensed);
          margin-bottom: 20px;
        }

        p {
          line-height: 26px;
          letter-spacing: 0.5px;
        }

        a {
          text-decoration: none;
        }
      }

      .hazard-image {
        align-items: center;
        display: flex;
        flex: 1;
        flex-basis: 50%;
        justify-content: center;

        img {
          aspect-ratio: 16/12;
          object-fit: cover;
          width: 100%;
        }
      }

      &:nth-child(even) {
        display: flex;
        flex-direction: row-reverse;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.hazard-forewarn-list {

  &:last-child {
    margin-bottom: 0;

    .block {
      margin-bottom: 0;

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include md {

  .hazard {

    .hazard-content {

      .hazard-card {
        display: flex;
        flex-direction: column-reverse;
        gap: 0;
        margin-bottom: 30px;

        .hazard-card-content {
          padding: 15px;

          .hazard-text {
            margin-bottom: 5px;
          }

          p {
            margin-bottom: 0;
          }
        }
        
        &:nth-child(even) {
          display: flex;
          flex-direction: column-reverse;

          .hazard-image {
            margin: 0;
            margin-bottom: 20px;
          }
        }

        .hazard-image {
          margin-bottom: 20px;
        }
      }
    }
  }
}
