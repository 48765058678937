// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.header {

  .navbar {
    background-color: var(--color-white);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    color: var(--color-black);
    left: 0;
    margin: 0 auto;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

    .logo-with-title {
      align-items: center;
      display: flex;
      gap: 180px;

      .navbar-brand {
        margin: 0;

        img {
          width: 100px;
          height: 85px;
        }
      }

      .navbar-title {

        * {
          color: var(--color-black);
          background-color: var(--color-aquamarine);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 0;
          padding: 3px 8px;
        }
      }
    }

    .navbar-toggler {
      border: 0;
      padding: 0;

      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        background-image: none;
        color: var(--color-aquamarine);
        font-family: var(--font-fontawesome);
        font-size: 36px;
        font-style: inherit;
        font-weight: 600;

        &::before {
          content: '\f00d';
          font-size: 36px;
        }
      }

      &.collapsed {

        .navbar-toggler-icon {

          &::before {
            content: '\f0c9';
            font-size: 32px;
          }
        }
      }
    }

    .navbar-collapse {

      .navbar-nav {

        li {
          padding: 43px 20px;

          a {
            cursor: pointer;
            color: var(--color-coral);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            padding: 0 !important;

            &:hover,
            &:focus {
              color: var(--color-aquamarine);
              background-color: transparent !important;
            }
          }

          i {
            display: none;
          }

          .dropdown-menu {
            border-bottom: 0;
            border-radius: 0;
            box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
            left: 0;
            top: 115px;
            // padding: 50px 0;
            padding: 20px 0;
            position: fixed;
            animation: fadeIn 0.3s linear;
            width: 100vw;

            li {
              padding: 0;
              margin-bottom: 20px;

              a {
                color: var(--color-black);
                font-weight: 400;
                text-transform: capitalize;
                text-decoration: none;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &.active,
              &:hover {

                a {
                  color: var(--color-aquamarine);
                }
              }
            }
          }

          @keyframes fadeIn {

            from {
              opacity: 0;
            }
            
            to {
              opacity: 1;
            }
          }

          &:hover {

            .dropdown-menu {
              display: block;
            }
          }

          &.active {

            a {
              color: var(--color-aquamarine);
            }
          }
        }

        .navbar-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 15px;
        }

        .middle-menu {
          max-width: 350px;
          padding-left: 75px;
        }
      }
    }
  }
}

@include xl {

  .header {

    .navbar {

      .logo-with-title {
        gap: 70px;
      }

      .navbar-collapse {

        .navbar-nav {

          li {
            padding: 33px 10px;
          }
        }
      }
    }
  }
}

@include lg {

  .header {

    .navbar {

      .logo-with-title {
        gap: 10px;

        .navbar-title {

          * {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }

      .navbar-collapse {

        .navbar-nav {
          display: flex;
          flex-direction: column;
          border-top: 1px solid var(--color-aquamarine);
          margin-top: 15px;
          padding-top: 10px;

          li {
            padding: 10px;
            position: relative;

            i {
              display: block;
              position: absolute;
              top: 5px;
              right: 25px;
              padding: 10px 5px 10px 135px;
            }

            .dropdown-toggle {

              &::after {
                border: 0;
                content: '\2b';
                font-family: var(--font-fontawesome);
                font-weight: 900;
                font-size: 12px;
              }

              &.show {

                &::after {
                  border: 0;
                  content: '\f068';
                  font-family: var(--font-fontawesome);
                  font-weight: 900;
                  font-size: 12px;
                }
              }
            }

            .dropdown-menu {
              border: none;
              box-shadow: none;
              height: auto;
              top: 0;
              position: relative;
              width: 100%;
              z-index: 999;
              display: none !important;

              &.show {
                display: block !important;
              }
            }
          }

          .middle-menu {
            padding-left: 16px;
            max-width: 100%;
          }
        }
      }
    }
  }
}
