// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.leaflet-container { /* all maps */
  width: 100%;
  height: 600px;
}

.legend {
  line-height: 18px;
  color: var(--color-dark-gray);
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
