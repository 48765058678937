// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.hackathon {
  display: flex;
  justify-content: center;

  .hackathon-content {
    width: 1000px;

    h2 {
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      font-family: var(--font-din-condensed);
      margin-bottom: 30px;
    }

    .hackathon-card {
      background-color: var(--color-white);
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      color: var(--color-black);
      display: flex;
      margin-bottom: 40px;
      padding: 29px;
      text-decoration: none;

      .hackathon-image {
        flex-basis: 40%;
        overflow: hidden;

        img {
          aspect-ratio: 16/12;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .hackathon-card-content {
        flex-basis: 60%;
        padding: 0 0 0 30px;

        .hackathon-card-top {
          display: flex;
          justify-content: space-between;

          .hackathon-date {
            background-color: var(--color-aquamarine);
            border-radius: 100px;
            border: 1px solid var(--color-aquamarine);
            color: var(--color-white);
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            margin: 0 0 20px;
            min-width: 110px;
            padding: 10px;
            text-transform: uppercase;
            transition: all 0.4s ease-in-out;
            text-align: center;

            .end-date {

              &::before {
                content: 'To';
                margin: 0 5px;
              }
            }

            &::before {
              content: '\f017';
              color: var(--color-white);
              font-weight: 600;
              font-family: var(--font-fontawesome);
            }
          }

          .hackathon-register {
            background-color: var(--color-aquamarine);
            border-radius: 100px;
            border: 1px solid var(--color-aquamarine);
            color: var(--color-white);
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            margin: 0 0 20px;
            padding: 10px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.4s ease-in-out;
          }
        }

        .hackathon-title {
          font-size: 26px;
          line-height: 33px;
          margin-bottom: 19px;
          text-transform: capitalize;
        }

        .hackathon-description {
          font-size: 16px;
          line-height: 22px;
          // margin-bottom: 25px;

          p {
            margin-bottom: 0 !important;
          }
        }
      }

      .hackathon-address {
        border-top: 1px solid var(--color-sweet-vanilla);
        display: flex;
        font-size: 18px;
        gap: 20px;
        margin-top: 25px;
        padding: 15px 0;

        .address {
          border-right: 1px solid var(--color-sweet-vanilla);
          flex-basis: 35%;

          &::before {
            content: '\f3c5';
            color: var(--color-aquamarine);
            font-weight: 600;
            font-family: var(--font-fontawesome);
            margin-right: 10px;
          }
        }

        .institution {
          flex-basis: 65%;

          p {
            margin: 0 0 0 31px;
            // margin-bottom: 0;
            // margin-left: 31px;
          }

          &::before {
            content: '\e56e';
            color: var(--color-aquamarine);
            font-weight: 600;
            font-family: var(--font-fontawesome);
            margin-right: 10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.hackathon-detail {
  background: var(--color-aquamarine);
  margin-bottom: 50px;

  .hackathon-detail-container {
    align-items: flex-start;
    background: url('/static/images/world-map.png') no-repeat center left;
    display: flex;
    justify-content: space-between;
    min-height: 400px;

    .hackathon-heading {
      padding: 50px 50px 50px 25px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 16px;
        font-size: 28px;
        line-height: 36px;
      }
    }

    .hackathon-promotion {
      padding: 50px;

      .promotion-details {
        background-color: var(--color-coral);
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
        color: var(--color-white);
        padding: 30px;

        h1,
        h2,
        h3 {
          margin-bottom: 16px;
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }
}

@include lg {

  .hackathon {

    .hackathon-content {

      h2 {
        font-size: 24px;
        line-height: 24px;
      }

      .hackathon-card {
        flex-direction: column;
        gap: 30px;
        padding: 0;

        .hackathon-card-content {
          padding: 0 15px;
          text-align: center;

          .hackathon-card-top {
            flex-direction: column;

            .hackathon-date {
              margin: 0 0 15px;
            }
          }


          .hackathon-title {
            line-height: 22px;
            margin-bottom: 15px;
            text-align: left;
          }

          .hackathon-address {

            .address {
              flex-basis: 50%;
              padding: 10px;
              
              p {
                text-align: center;
              }
            }

            .institution {
              flex-basis: 50%;
              padding: 10px;
              
              p {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .hackathon-detail {

    .hackathon-detail-container {

      .hackathon-heading {
        padding: 30px 15px;

        p {
          margin-bottom: 0;
        }
      }

      .hackathon-promotion {
        padding: 20px;

        .promotion-details {
          padding: 30px 15px;

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
