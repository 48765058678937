// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.page-title {

  h1 {
    border-bottom: 1px solid var(--color-aquamarine);
    font-size: 48px;
    font-weight: 900;
    font-family: var(--font-din-condensed);
    line-height: 56px;
    letter-spacing: 0.5px;
    padding-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
  }
}

@include lg {

  .page-title {

    .row {

      h1 {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}
