// Media Screen
$screen-sm-max: 550px;	// Small tablets and large smartphones (landscape view)
$screen-md-max: 767px;	// Small tablets (portrait view)
$screen-lg-max: 991px;	// Tablets and small desktops
$screen-xl-max: 1199px;	// Large tablets and desktops
$screen-xxl-max: 1399px;

// Mixins for meadia query
@mixin sm {
  // Small devices
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  // Medium devices
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  // Large devices
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  // Extra large devices
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin xxl {
  // Extra extra large devices
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}
