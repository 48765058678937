// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.hazard-list {
  margin-bottom: 50px;
  
  .hazard-list-container {
    
    .hazard-title {
      font-size: 36px;
      font-weight: bold;
      font-family: var(--font-din-condensed);
      line-height: 36px;
      margin-bottom: 15px;
    }

    .hazard-text {
      
      * {
        color: var(--color-black);
        letter-spacing: 0.4px;
        margin-bottom: 30px;
        text-align: justify;
      }
    }

    .hazard-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
      padding-top: 10px;

      .hazard-card {
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
        position: relative;

        img {
          aspect-ratio: 16/9;
          width: 100%;
          object-fit: cover;
        }

        .hazard-card-container {
          padding: 30px 20px 70px;

          .hazard-badge {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 15px;
    
            a {
              background: var(--color-aquamarine-transparent);
              border-radius: 30px;
              color: var(--color-black);
              font-weight: 500;
              padding: 5px 15px;
              text-decoration: none;
            }
          }

          .hazard-card-content {

            .hazard-card-text {
              font-weight: bold;
              font-size: 21px;
              font-family: var(--font-din-condensed);
              color: var(--color-black);
              line-height: 32px;
              letter-spacing: 0.5px;
              margin-bottom: 15px;
            }

            p {
              color: var(--color-gray2);
              margin-bottom: 25px;
            }
          }

          .btn {
            position: absolute;
            bottom: 30px;
          }
        }
      }
    }
  }
}

@include lg {

  .hazard-list {

    .hazard-list-container{

      .hazard-title {
        font-size: 24px;
        line-height: 24px;
      }

      .hazard-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@include md {

  .hazard-list {

    .hazard-list-container {
  
      .hazard-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
      
      img {
        aspect-ratio: 16/11;
      }
    }
  }
}
