// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.hero-banner {
  background: var(--color-coral);
  padding: 100px 0;
  position: relative;
  z-index: 1;

  .banner-info {
    background: url('/static/images/world-map.png') no-repeat center left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .banner-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrapper {
      position: relative;
      padding: 15px;

      img {
        aspect-ratio: 1/1;
        clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        object-fit: cover;
        width: 100%;
      }

      &::before {
        content: '';
        background-color: var(--color-light-aquamarine);
        position: absolute;
        display: block;
        inset: 0;
        clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
      }
    }

    .banner-detail {

      .banner-content {

        .banner-detail-title {
          color: var(--color-light-grey);
          font-family: var(--font-din-condensed);
          font-size: 38px;
          font-weight: 600;
          line-height: 1.4;
          margin-bottom: 25px;
        }

        p {
          color: var(--color-white);
          letter-spacing: 0.4px;
          margin-bottom: 30px;
          margin-top: 10px;

          * {
            color: var(--color-white) !important;
          }
        }

        .banner-detail-button {
          background-color: var(--color-aquamarine);

          &:hover {
            color: var(--color-black);
          }
        }
      }
    }
  }

  .carousel-indicators {
    bottom: -50px;
    margin: 0;
  }

  .carousel-control-prev {
    left: -40px;
    width: 35px;
  }

  .carousel-control-next {
    right: -40px;
    width: 35px;
  }
}

@include lg {

  .hero-banner {
    padding: 50px 0;

    .banner-info {
  
      .banner-container {
        flex-direction: column-reverse;
        gap: 20px;
      }

      .image-wrapper {
        padding: 20px;
  
        img {
          aspect-ratio: 3/3;
        }
      }
  
      .banner-detail {
  
        .banner-content {
  
          .banner-detail-title {
            font-size: 32px;
            margin-bottom: 20px;
            margin-top: 0;
          }

          p {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }

    .custom-carousel-control {
  
      &.left {
        left: -5px;
      }
  
      &.right {
        right: -5px;
      }
    }

    .carousel-indicators {
      display: none;
    }

    .carousel-control-prev {
      display: none;
    }

    .carousel-control-next {
      display: none;
    }
  }
}

@include md {

  .hero-banner {

    .banner-info {
      display: flex;
      flex-direction: column;

      .banner-image {

        img {
          aspect-ratio: 16/9;
        }
      }

      .banner-detail {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
