// Colors.
//
// All Sass color variables prefixed with $color-.
// This makes it easy to figure out what a variable
// is for later.
//
// If you need help naming colors check out:
// http://chir.ag/projects/name-that-color/

$greenColor: #36e4af;
$secondColor: #fe6a46;
$bgColor: #fff;
$blackColor: #000;
$grayColor: rgb(128, 128, 128);
