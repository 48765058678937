// Fonts generated using:
// https://www.fontsquirrel.com/tools/webfont-generator
//
// For more information on font-face and browser support visit:
// https://css-tricks.com/snippets/css/using-font-face/

// Lato Bold.
// @font-face {
//   font-family: 'FontName';
//   font-style: normal;
//   font-weight: 700;
//   // The browser will hide the text for about 100ms and, if the font has not yet
//   // been downloaded, will use the fallback text. It will swap to the new font
//   // after it is downloaded, but only during a short swap period
//   // https://css-tricks.com/almanac/properties/f/font-display/
//   font-display: fallback;
//   src:
//     url('../fonts/font-name.woff2') format('woff2'),
//     url('../fonts/font-name-lato-bold.woff') format('woff');
// }

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 100;
  src: url('../fonts/BarlowCondensed-Thin.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 100;
  src: url('../fonts/BarlowCondensed-ThinItalic.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 200;
  src: url('../fonts/BarlowCondensed-ExtraLight.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 200;
  src: url('../fonts/BarlowCondensed-ExtraLight.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 300;
  src: url('../fonts/BarlowCondensed-Light.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 300;
  src: url('../fonts/BarlowCondensed-LightItalic.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('../fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 500;
  src: url('../fonts/BarlowCondensed-Medium.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 500;
  src: url('../fonts/BarlowCondensed-Medium.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 600;
  src: url('../fonts/BarlowCondensed-SemiBold.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 600;
  src: url('../fonts/BarlowCondensed-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src: url('../fonts/BarlowCondensed-Bold.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 700;
  src: url('../fonts/BarlowCondensed-BoldItalic.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 800;
  src: url('../fonts/BarlowCondensed-ExtraBold.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 800;
  src: url('../fonts/BarlowCondensed-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-display: fallback;
  font-weight: 900;
  src: url('../fonts/BarlowCondensed-Black.ttf');
}

@font-face {
  font-family: 'BarlowCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 900;
  src: url('../fonts/BarlowCondensed-Black.ttf');
}

@font-face {
  font-family: 'DINCondensed';
  font-style: italic;
  font-display: fallback;
  font-weight: 900;
  src: url('../fonts/DINCondensed-Bold.ttf');
}
