// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../global/utils/init';

.container {
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;
}

.row {
  margin-left: -20px;
  margin-right: -20px;

  > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@include xxl {

  .container {
    max-width: 1320px;
  }
}

@include xl {

  .container {
    max-width: 1140px;
  }
}

@include lg {

  .container {
    max-width: 960px;
  }
}

// @include md {
  
// }

@include sm {

  .container {
    max-width: 540px;
  }
}
