// Utilities
//
// Import variables and mixins
// Should contain NO css output.
// Only variables / mixins / settings.
@import '../../global/utils/init';

.resource {

  .resource-content {
    
    h2 {
      color: var(--color-coral);
      font-size: 48px;
      font-weight: 700;
      font-family: var(--font-din-condensed);
      margin-bottom: 25px;
    }

    .resource-description {
      
      * {
        font-weight: 400;
        letter-spacing: 0.4px;
        margin-bottom: 30px;
      }
    }

    .resource-search-container {
      margin-bottom: 30px;

      .input-container {
        display: flex;
        gap: 30px;

        .search-box {
          position: relative;

          .search-button {
            border-color: var(--color-gray2);
            border-radius: 0;
            padding: 10px 30px;
          }
      
          .fa-magnifying-glass {
            top: 15px;
            left: 8px;
            position: absolute;
            z-index: 99;
          }
        }
      }

      .select-button {
        border-color: var(--color-gray2);
        border-radius: 0;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .resource-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      .resource-card {
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 20px 20px 70px;
        position: relative;

        .photo {
          margin-bottom: 30px;

          img {
            aspect-ratio: 16/9;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
            width: 100%;
          }
        }

        .content {
          
          .photo-text {
            color: var(--color-coral);
            font-size: 26px;
            font-weight: 600;
            font-family: var(--font-din-condensed);
            line-height: 32px;
            letter-spacing: 0.5px;
            margin-bottom: 15px;
          }
  
          p {
            color: var(--color-gray2);
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.5px;
            margin-bottom: 0;

            &:last-child {
              margin-bottom: 25px;
            }
          }
        }

        .btn {
          position: absolute;
          bottom: 30px;
        }
      }
    }
  }
}

.resource-details-page {

  .resource-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    .resource-image {
      margin-bottom: 30px;
      margin-top: 50px;

      img {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
      }
    }

    .description {

      p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.4px;
        margin-bottom: 30px;
      }
    }

    .download-button {
      background-color: var(--color-tamato);
      border: 0;
      border-radius: 5px;
      color: var(--color-white);
      display: flex;
      font-size: 18px;
      margin: 0 auto;
      padding: 12px 30px;
      text-decoration: none;
      width: fit-content;
  
      &::before {
        content: '\f019';
        font-family: var(--font-fontawesome);
        font-weight: bold;
        margin-right: 15px;
      }
    }
  }
}

@include lg {

  .resource {

    .resource-content {

      h2 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 15px;
      }

      .resource-search-container {

        .input-container {
          flex-direction: column;
          gap: 20px;
        }
      }

      .resource-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .resource-details {
    flex-direction: column;
    gap: 30px;
  
    .content {
      flex: 0 0 100%;
    }
  
    .image {
      flex: 0 0 100%;
    }
  }
}

@include md {

  .resource {

    .resource-content {

      .resource-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
      }
    }
  }
}
